import Vue from "vue";
import Vuex from "vuex";
import router, {resetRouter} from "@/router";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentPathName: ''
    },
    mutations: {
        setPath(state) {
            state.currentPathName = localStorage.getItem("currentPathName")
        },
        logout() {
            //清空缓存
            localStorage.removeItem("user")
            localStorage.removeItem("menus")
            router.push("/login").then(r => {
                this.$message({
                    message: '内容已修改，请重新登录',
                    type: 'warning'
                })
            })
            //重置路由
            resetRouter()
        }
    }
})

export default store