import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css'
import request from "@/utils/request";
import store from "./store";
import VueParticles from "vue-particles";
import vueCropper from "vue-cropper/example/src/vue-cropper";
import 'mavon-editor/dist/css/index.css';
import mavonEditor from 'mavon-editor';


Vue.config.productionTip = false
Vue.use(ElementUI, {size: "mini"})
    .use(VueParticles)
    .use(vueCropper)
    .use(mavonEditor);
Vue.prototype.request = request
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
