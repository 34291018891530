import axios from "axios";
import ElementUI from "element-ui";
import router from '../router'
const request = axios.create({
    baseURL: '/api',
    timeout: 10000
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['contentType'] = 'application/json;charset=UTF-8';
    // console.log(localStorage.getItem("user"))
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    if (user) {
        config.headers['token'] = user.token; //设置请求头
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(response => {
    let res = response.data;
    //如果时返回的文件
    if (response.config.responseType === 'blob') {
        return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === 'string') {
        res = res ? JSON.parse(res) : res
    }
    //权限不通过给予提示
    if (res.code === '401' || res.code === '602') {
        ElementUI.Message({
            message: res.msg,
            type: "error"
        });
        router.push("/login")
    }
    return res;
}, error => {
    console.log("error:" + error);
    return Promise.reject(error);
});

export default request